
.btn {
	&,
	&:hover,
	&:active,
	.active {
		box-shadow: none;
	}
}

.x-overlay {
	.bg-rgba(100, 100, 100, 0.75);
	.btn-secondary {
		color: @text-invert-color;
	}
}

#header {
	.main-menu {
		background: @gray-bg;
	}
}

#main {
	> .red-stripe {
		background: @gray-light2;
	}
	> .bg-title.black {
		background: @gray-bg;
	}
}

#page.video-page {
	#video-views-votes{
		.vote-actions{
			.thumb-up{
				.btn:hover &.black-hover,
				&.black-hover:hover,
				&.black{
					color: white;
				}
			}
			.thumb-down{
				&.grey{
					color: black;
				}
			}
		}
	}
	#video-tabs{
		.text-danger{
			color: #fff;
		}
	}
}

.premium-page {
	#main {
		.head-message {
			strong {
				.text-danger {
					background: @theme-color;
					color: #fff;
					padding: 1px 4px;
				}
			}
		}
	}
}

.mozaique {
	.thumb-block {
		.profile-counts {
			color: @text-color;
		}
	}
	
	&.profileslist {
		.thumb-block {
			p {
				color: @text-color;
				a {
					color: @text-color;
				}
			}
		}
	}
}

#related-playlists {
	.mozaique {
		.thumb-block {
			.thumb-under {
				p.title {
					span {
						&.creator {
							color: #aaa;
						}
					}
				}
			}
		}
	}
}

.search-autocomplete {
	li a .highlighted {
		background: @theme-color;
	}
}

@media (max-width: @screen-sm) {
	#header .main-menu {
		background: @theme-color;
	}
}

#footer .botLinks {
	&, a {
		color: @text-color;
	}
}
